<script setup lang="ts">
defineProps<{
  y?: number;
  color?: string;
}>();
const { status } = useAuth();
</script>
<template>
  <span
    class="flex sticky inset-x-0 top-0 w-full gap-4 p-4 justify-between z-50 bg-opacity-80 transition-colors duration-300 px-7 border-b border-primary-200"
    :class="
      color ? `bg-${color}` : y && y > 0 ? 'bg-primary-100' : 'bg-transparent'
    "
  >
    <UIcon name="i-respell-logo" class="text-3xl" />

    <UButton
      v-if="status === 'authenticated'"
      to="/"
      label="Home"
      variant="solid"
      color="black"
      size="xl"
      icon="i-ph-arrow-right"
      trailing
      :ui="{
        rounded: 'rounded-full',
      }"
    />

    <span v-else class="flex gap-2 ml-auto">
      <UButton
        to="/register"
        label="Get started"
        variant="outline"
        size="xl"
        color="black"
        :ui="{
          rounded: 'rounded-full',
        }"
      />
      <UButton
        to="/login"
        label="Login"
        variant="solid"
        size="xl"
        color="black"
        icon="i-ph-arrow-right"
        trailing
        :ui="{
          rounded: 'rounded-full',
        }"
      />
    </span>
  </span>
</template>
